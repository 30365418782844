<template>
  <div>
    <section>
      <div>
        <h2 style="font-size:24px;color:#0075be;padding:10px">Profile Detail View - Employee</h2>
      </div>
    </section>

    <section class="content-tabs">
      <div class="content-tabs-container">
        <div class="cs-tri">
          <div>Profile: <strong v-if="employee">{{ employee.employeeId }}</strong></div>
          <div>Name: <strong v-if="employee">{{ employee.firstName }} {{ employee.lastName }}</strong></div>
          <div>Profile Status: <strong v-if="employee">{{ employee.status }}</strong></div>
        </div>
        <ul class="ctabs first-row">
            <li class="active"><a href="">Employee Info</a></li>
            <li><a href="">Documents</a></li>
            <li><a href="">Notes</a></li>
            <li><a href="">Emails</a></li>
        </ul>
        <ul class="ctabs second-row">
            <li><a href="">Access</a></li>
            <li><a href="">Permissions</a></li>
            <li><a href="">User Log</a></li>
        </ul>
      </div>
    </section>

    <section class="client-properties active">
      <div class="cp-box">
        <ul class="cp-rows active">
          <li>
            <h4>General Information</h4>
            <ul class="cp-fields justify-content-none">
              <li>
                <span>Employee ID</span>
                <div class="val" v-if="employee">
                  {{ employee.employeeId }}
                </div>
              </li>
              <li>
                <span>Role</span>
                <div class="val" v-if="employee">
                  {{ employee.role.name }}
                </div>
              </li>
              <li>
                <span>First Name</span>
                <div class="val" v-if="employee">
                  {{ employee.firstName }}
                </div>
              </li>
              <li>
                <span>Last Name</span>
                <div class="val" v-if="employee">
                  {{ employee.lastName }}
                </div>
              </li>
            </ul>
          </li>
          <li>
            <h4>Contact Information</h4>
            <ul class="cp-fields justify-content-none">
              <li>
                <span>Email</span>
                <div class="val" v-if="employee">
                  {{ employee.email }}
                </div>
              </li>
              <li>
                <span>Phone</span>
                <div class="val" v-if="employee">
                  {{ employee.phone }}
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
    <section class="client-properties">
    </section>
    <section class="client-properties">
      <div class="cp-box">
        <ul class="cp-rows active">
          <li>
            <button class="btn btn-success" v-on:click="addEmployeeNotes">Add New</button>
          </li>
          <li>
            <h4>Profile Notes</h4>
            <br>
            <ul class="cp-fields profile-lists">
              <li>
                <span>Date / Time</span>
              </li>
              <li>
                <span>Added By</span>
              </li>
              <li>
                <span>Note</span>
              </li>
            </ul>
            <ul class="cp-fields profile-lists" v-for="employeenote in employeenotes">
              <li>
                {{ employeenote.createdAt | date }}
              </li>
              <li>
                {{ employeenote.addedBy }}
              </li>
              <li class="note-area">
                {{ employeenote.notes }}
              </li>
            </ul>
          </li>
          <li v-if="newItemStatus">
            <h4>Add New Note</h4>
            <ul class="cp-fields">
              <textarea rows="5" class="textarea-profile-notes" v-model="employeeNotes"></textarea>
            </ul>
            <ul class="cp-fields">
              <li>
                <button class="btn btn-success" v-on:click="saveEmployeeNotes">Save</button>
                <button class="btn btn-warning" v-on:click="cancelEmployeeNotes">Cancel</button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
    <section class="client-properties">
    </section>
    <section class="client-properties">
      <div class="cp-box">
        <ul class="cp-rows active">
          <li>
            <button class="btn btn-success" v-on:click="">Reset Activation Code</button>
            <button class="btn btn-success" v-on:click="">Reset Password</button>
            <button class="btn btn-success" v-on:click="">Block</button>
          </li>
          <li>
            <h4>Access Information</h4>
            <ul class="cp-fields justify-content-none">
              <li>
                <span>Activation Code</span>
                <div class="val" v-if="employee">
                  {{ employee.activationCode.activationCode }}
                </div>
              </li>
              <li>
                <span>Activation Code Expires</span>
                <div class="val" v-if="employee">
                  {{ employee.activationCode.activationCodeExpiryDate }}
                </div>
              </li>
              <li>
                <span>Activation Date</span>
                <div class="val" v-if="employee">
                  {{ employee.activationCode.activationDate }}
                </div>
              </li>
              <li>
                <span>Reset Pass Request</span>
                <div class="val" v-if="employee">
                  {{ employee.activationCode.resetPasswordRequestedDateTime }}
                </div>
              </li>
              <li>
                <span>Reset Pass Complete</span>
                <div class="val" v-if="employee">
                  {{ employee.activationCode.resetPasswordCompletedDateTime }}
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
    <section class="client-properties">
    </section>
  </div>
</template>

<script>
  export default {
    name: 'Employees',

    props: {
      id: {
        type: [String, Number],
        required: true
      }
    },

    data: ()=>({
      employeeNotes: '',
      newItemStatus: false
    }),

    computed: {
      employee () {
        return this.$store.getters['employees/get'](this.id)
      },
      employeenotes () {
        return this.$store.getters['employeenotes/list']
      }
    },

    created () {
      this.$store.dispatch('employees/get', this.id)
      this.$store.dispatch('employeenotes/list', {'employeeId':this.id})
    },

    methods: {
      saveEmployeeNotes: function (event) {
        this.$store.dispatch('employeenotes/createProfileNotes', {'employeeId':this.id, 'notes': this.employeeNotes, 'createdAt': 'CURRENT_TIMESTAMP'})
        this.newItemStatus = false
        this.employeeNotes = ''
      },

      addEmployeeNotes: function (event) {
        this.newItemStatus = true
      },

      cancelEmployeeNotes: function (event) {
        this.newItemStatus = false
        this.employeeNotes = ''
      }
    }
  }

</script>