<template>
<div>
  <section>
    <div>
      <h2 style="font-size:24px;color:#0075be;padding:10px">Settings > Employees</h2>
    </div>
    <em class="add-emp" style="cursor: pointer; display: inline-block; margin: 10px; padding: 0 10px; line-height: 26px; vertical-align: middle; background: #67c23a; border-radius: 4px; color: white;" v-on:click.prevent="openEmployee">+ New Employee</em>
  </section>
  <section class="content-tlist">
    <table class="content-table">
      <tr>
        <th>Date</th>
        <th>Emp ID</th>
        <th>Role</th>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Email</th>
        <th>Status</th>
        <th>Options</th>
      </tr>
      <tr v-for = "employee in employees">
        <td><span>Date</span>{{ employee.createdAt | date("MMMM D YYYY") }}</td>
        <td><span>Emp ID</span><a :href="`/settings/employees/${employee.id}`">{{ employee.employeeId }}</a></td>
        <td><span>Role</span>{{ employee.role.name }}</td>
        <td><span>First Name</span>{{ employee.firstName }}</td>
        <td><span>Last Name</span>{{ employee.lastName }}</td>
        <td><span>Email</span>{{ employee.email }}</td>
        <td><span>Status</span>
          <em class="c1" v-if="employee.status == 'Active'">{{ employee.status }}</em>
          <em class="c2" v-if="employee.status == 'Pending'">{{ employee.status }}</em>
          <em class="c3" v-if="employee.status == 'Disabled' || employee.status == 'Blocked'">{{ employee.status }}</em>
        </td>
        <td><span>Options</span><em style="background:#000; color: #fff;">Block</em></td>
      </tr>
    </table>
  </section>
</div>
</template>

<script>
export default {
  data: ()=>({
    open: false,
    user: null,
    employees: [],
  }),
  methods: {
    openEmployee() {
      window.employeePopup.showUser({});
    },

    async loadEmployees() {
      this.employees=(await api.list("Employees")).items;
    },
  },

  mounted() {
    this.loadEmployees();
    window.employeePopup=this;
    window.employeeList=this;
  },
}
</script>
