<template>
  <div>
    <section>
      <div>
        <h2 style="font-size:24px;color:#0075be;padding:10px; margin-bottom:50px">My Profile</h2>
      </div>
    </section>
    <section class="client-properties active">
      <div class="cp-box">
        <ul class="sub-tabs" style="margin-bottom:50px">
          <li class="active"><a>My Info</a></li>
          <li class=""><a>User Log</a></li>
          <li class=""><a style="background:limegreen">Reset Password</a></li>
        </ul>
        <ul class="cp-rows active">
          <li>
            <ul class="cp-fields justify-content-none">
              <li>
                <span>Active Since</span>
                <div class="val" v-if="user">
                  {{ user.activationDate }}
                </div>
              </li>
              <li>
                <span>Employee ID</span>
                <div class="val" v-if="user">
                  {{ user.employee.employeeId }}
                </div>
              </li>
              <li>
                <span>Role</span>
                <div class="val" v-if="user">
                  {{ user.employee.roles.name }}
                </div>
              </li>
            </ul>
          </li>
          <li>
            <h4 style="margin-bottom:10px">Employee Information</h4>
            <ul class="cp-fields justify-content-none">
              <li>
                <span>First Name</span>
                <div class="val" v-if="user">
                  {{ user.firstName }}
                </div>
              </li>
              <li>
                <span>Last Name</span>
                <div class="val" v-if="user">
                  {{ user.lastName }}
                </div>
              </li>
              <li>
                <span>Email Address</span>
                <div class="val" v-if="user">
                  {{ user.email }}
                </div>
              </li>
              <li>
                <span>Phone Number</span>
                <div class="val" v-if="user">
                  {{ user.employee.phone }}
                </div>
              </li>
            </ul>
          </li>
        </ul>

        <ul class="cp-rows">
          <li>
            <ul class="cp-fields justify-content-none">
              <li>
                <span>Last Login</span>
                <div class="val" v-if="user">
                  {{ user.lastLogin }}
                </div>
              </li>
              <li>
                <span>Data Transfer</span>
                <div class="val" v-if="user">
                  {{ user.dataTransfer }}
                </div>
              </li>
              <li>
                <span>IP Address</span>
                <div class="val" v-if="user">
                  {{ user.ipAddress }}
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>

  export default {
    name: 'MyProfile',

    data: () => ({
      user: null,
    }),

    methods: {
      async getProfileInfo() {
        this.user = (await api.action("User", window.appData.currentUser.id, "ProfileInfo")).item;
      },
    },

    mounted() {
      this.getProfileInfo()
    }

  }
</script>
